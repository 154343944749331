import { AspectRatio } from 'components/commons/aspect-ratio';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import NextImage from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { ChooseUsItemCard, Container, ImageContainer, styles } from './style';
import { Section } from '../style';

export default function AboutUsSection() {
  const { t } = useTranslation();
  return (
    <Section css={{ mt: 64, mb: 80 }}>
      <Container>
        <ImageContainer css={{ zIndex: -1 }}>
          <AspectRatio ratio={5 / 8}>
            <NextImage
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
                borderRadius: 8,
              }}
              fill
              alt="about-us"
              src="/assets/home-about-us.png"
            />
          </AspectRatio>
        </ImageContainer>
        <Stack grow gap="xl">
          <Stack.Item>
            <Text
              id="about-us"
              variant="headline4"
              color="primary"
              weight="bold"
            >
              {t('common:about_us')}
            </Text>
            <Text variant="body2" css={{ mt: 4, textAlign: 'justify' }}>
              {t('common:wjl_about_us_full')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text variant="headline4" color="primary" weight="bold">
              {t('common:reason_to_choose_us')}
            </Text>
            <Stack rootCss={{ pt: 16 }}>
              <Stack direction="horizontal" wrap gap="md">
                <Stack.Item grow css={styles.chooseUsItemCard}>
                  <ChooseUsItemCard>
                    <NextImage
                      src="/assets/practical-in-ordering.png"
                      alt="practical-in-ordering"
                      height={72}
                      width={72}
                    />
                    <Text
                      variant="headline5"
                      align="center"
                      css={styles.cardTitle}
                    >
                      {t('common:practical_in_ordering')}
                    </Text>
                    <Text variant="body4" align="center">
                      {t('common:practical_in_ordering_message')}
                    </Text>
                  </ChooseUsItemCard>
                </Stack.Item>
                <Stack.Item grow css={styles.chooseUsItemCard}>
                  <ChooseUsItemCard>
                    <NextImage
                      src="/assets/refund.png"
                      alt="refund"
                      height={72}
                      width={72}
                    />
                    <Text
                      variant="headline5"
                      align="center"
                      css={styles.cardTitle}
                    >
                      {t('common:easy_refund_process')}
                    </Text>
                    <Text variant="body4" align="center">
                      {t('common:easy_refund_process_message')}
                    </Text>
                  </ChooseUsItemCard>
                </Stack.Item>

                <Stack.Item grow css={styles.chooseUsItemCard}>
                  <ChooseUsItemCard>
                    <NextImage
                      src="/assets/payment-method.png"
                      alt="payment-method"
                      height={72}
                      width={72}
                    />
                    <Text
                      variant="headline5"
                      align="center"
                      css={styles.cardTitle}
                    >
                      {t('common:various_payment_methods')}
                    </Text>
                    <Text variant="body4" align="center">
                      {t('common:various_payment_methods_message')}
                    </Text>
                  </ChooseUsItemCard>
                </Stack.Item>

                <Stack.Item grow css={styles.chooseUsItemCard}>
                  <ChooseUsItemCard>
                    <NextImage
                      src="/assets/transaction-security.png"
                      alt="transaction-security"
                      height={72}
                      width={72}
                    />
                    <Text
                      variant="headline5"
                      align="center"
                      css={styles.cardTitle}
                    >
                      {t('common:guarantee_transaction_security')}
                    </Text>
                    <Text variant="body4" align="center">
                      {t('common:guarantee_transaction_security_message')}
                    </Text>
                  </ChooseUsItemCard>
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack.Item>
        </Stack>
      </Container>
    </Section>
  );
}
