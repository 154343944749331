import { AspectRatio } from 'components/commons/aspect-ratio';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';

import { ImageItemContainer, Section, styles } from './style';

const imageList = [
  {
    alt: 'Wira Mutiara',
    src: '/assets/our_fleet_01.jpeg',
  },
  {
    alt: 'Wira Berlian',
    src: '/assets/our_fleet_02.jpeg',
  },
  {
    alt: 'Wira Ono Niha',
    src: '/assets/our_fleet_03.jpeg',
  },
  {
    alt: 'Wira Victoria',
    src: '/assets/our_fleet_04.jpeg',
  },
  {
    alt: 'Wira Glory',
    src: '/assets/our_fleet_05.jpeg',
  },
  {
    alt: 'Wira Nauli',
    src: '/assets/our_fleet_06.jpeg',
  },
  {
    alt: 'WJL Fleet 07',
    src: '/assets/our_fleet_07.jpeg',
  },
  {
    alt: 'Wira Kencana',
    src: '/assets/our_fleet_08.jpeg',
  },
];

export default function OurFleetSection() {
  const { t } = useTranslation();
  const [paddingCarousel, setPaddingCarousel] = React.useState({
    isLoaded: false,
    pad: 0,
  });
  const items = React.useMemo(
    () =>
      imageList.map((item) => (
        <ImageItemContainer>
          <AspectRatio key={item.alt} ratio={16 / 10}>
            <Image
              style={{
                objectFit: 'cover',
                objectPosition: '50% 50%',
                borderRadius: 8,
              }}
              fill
              className="item"
              alt={item.alt}
              src={item.src}
            />
          </AspectRatio>
        </ImageItemContainer>
      )),
    [],
  );

  React.useEffect(() => {
    if (typeof window !== undefined) {
      const { width } = window && window.screen;
      if (width < 576) {
        setPaddingCarousel({ pad: 0, isLoaded: true });
      }
      if (width >= 576) {
        setPaddingCarousel({ pad: (width - 576) / 2, isLoaded: true });
      }
      if (width >= 768) {
        setPaddingCarousel({ pad: (width - 768) / 2, isLoaded: true });
      }
      if (width >= 992) {
        setPaddingCarousel({ pad: (width - 992) / 2, isLoaded: true });
      }
      if (width >= 1200) {
        setPaddingCarousel({ pad: (width - 1200) / 2, isLoaded: true });
      }
    }
  }, []);

  return (
    <Section css={{ mb: 80 }}>
      <Stack rootCss={{ ...styles.breakPointsCss, mb: 16 }}>
        <Text variant="headline3" weight="bold" color="primary">
          {t('common:our_fleet')}
        </Text>
      </Stack>
      {paddingCarousel.isLoaded && (
        <AliceCarousel
          autoWidth
          disableDotsControls
          disableButtonsControls
          items={items}
          autoPlay
          autoPlayInterval={1000}
          infinite
          paddingLeft={paddingCarousel.pad}
          paddingRight={paddingCarousel.pad}
          mouseTracking
        />
      )}
    </Section>
  );
}
